.card-main {
    background-color: rgba(255, 255, 255, 0.048);
    transition: all 0.4s ease-in-out;
}

.card-main:hover{
    scale: 1.1;
    cursor: default;
}

.card-holder-main {
    background-color: rgba(16, 0, 75, 0.925);
}

.muted-data{
    color: rgb(176, 176, 176);
}