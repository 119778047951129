/* Footer.css */
.footer {
    background-color: #333;
    color: #fff;
    padding: 20px;
    text-align: center;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-title {
    margin: 0;
    font-size: 24px;
}

.footer-details {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    gap: 200px; /* Adjust spacing between items */
}

.footer-item {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.footer-item i {
    margin-right: 5px;
}

.footer-item a {
    color: #fff;
    text-decoration: none;
}

.phone-item {
    margin-left: 20px; /* Adjust the margin to move the phone number slightly to the right */
}
