.holdd {
    background-image: url('../../assets/man-image.png');
    background-size: 90%;
    background-repeat: no-repeat;
	 
}

.img-hold {
    scale: 1;
    transition: all 0.4s ease-in-out;
}

.img-hold:hover {
    scale: 1.05;
}

.background {
    background-image: url('../../assets/flag.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
}

.content {
    position: relative;
}
