.auth-buttons {
  display: flex;
  align-items: center;
}

.sticky-nav {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: white;
}

.menu-item {
  color: black;
}


.me-auto {
  font-size: 2.5vh;
  margin: auto;
}

.navlinks-middle {
  transition: all 0.2s ease-in;
}

.navlinks-middle:hover {
  color: blue;
}

/* Adjusting styles for smaller screens */
@media (max-width: 1000px) {
  .auth-buttons {
    margin-top: 10px;
    /* Adjust margin for better spacing in small screens */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

  }

  .me-auto {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 10px;
    /* Adjust margin for better spacing in small screens */
  }

  .me-auto .nav-link {
    background-color: transparent;
    /* Remove background color for smaller screens */
  }

  .auth-buttons .auth-button {
    margin-top: 10px;
    width: 20vw;
    height: 5vh;

  }

}