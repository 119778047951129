* {
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.App {
  /* text-align: center; */
  
}

::-webkit-scrollbar{
  width: 10px;
  height: 7px;
}

::-webkit-scrollbar-track{
  background-color: transparent;
}

::-webkit-scrollbar-thumb{
  background-color: #374bff;
  border-radius: 30px;
}




.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



.blob {
  width: 112px;
  height: 112px;
  display: grid;
  background: #fff;
  filter: blur(4.5px) contrast(10);
  padding: 11.2px;
  mix-blend-mode: darken;
}

.blob:before {
  content: "";
  margin: auto;
  width: 33.6px;
  height: 33.6px;
  border-radius: 50%;
  color: #474bff;
  background: currentColor;
  box-shadow: -33.6px 0, 33.6px 0, 0 33.6px, 0 -33.6px;
  animation: blob-031hhgmd 1s infinite alternate;
}

@keyframes blob-031hhgmd {

  90%,
  100% {
    box-shadow: -11.2px 0, 11.2px 0, 0 11.2px, 0 -11.2px;
    transform: rotate(180deg);
  }
}